<template>
    <div v-if="device">
        <div class="container repair-container">
            <loading-state
                    v-if="loading"
                    title="Loading iris codes">
            </loading-state>

            <template v-if="!showSubmit">
                <h2>Choose Fault</h2>
                <ul class="list-group selection-list">
                    <li class="list-group-item" :class="{'active': selectedOption === 'device-fault'}">
                        <label class="custom-control custom-radio mb-0">
                            <input type="radio" class="custom-control-input" name="faulty-status" value="device-fault" id="fault-device-fault" v-model="selectedOption">
                            <label class="custom-control-label mb-0" for="fault-device-fault">Device Fault</label>
                        </label>
                    </li>
                    <li class="list-group-item" :class="{'active': selectedOption === 'no-fault-found'}">
                        <label class="custom-control custom-radio mb-0">
                            <input type="radio" class="custom-control-input" name="faulty-status" value="no-fault-found" id="fault-fault-not-found" v-model="selectedOption">
                            <label class="custom-control-label mb-0" for="fault-fault-not-found">No Fault Found</label>
                        </label>
                    </li>
                    <li class="list-group-item" :class="{'active': selectedOption === 'software-upgrade'}">
                        <label class="custom-control custom-radio mb-0">
                            <input type="radio" class="custom-control-input" name="faulty-status" value="software-upgrade" id="fault-software-upgrade" v-model="selectedOption">
                            <label class="custom-control-label mb-0" for="fault-software-upgrade">Software Upgrade</label>
                        </label>
                    </li>
                </ul>

                <div v-if="noFaultFoundHasBeenSelected" class="form-group mt-4">
                    <h5>Please specify</h5>
                    <ul class="list-group selection-list">
                        <li class="list-group-item" :class="{'active': selectedProblem === 'no-fault-found'}">
                            <label class="custom-control custom-radio mb-0">
                                <input type="radio" class="custom-control-input" name="problem" value="no-fault-found" id="problem-no-fault-found" v-model="selectedProblem">
                                <label class="custom-control-label mb-0" for="problem-no-fault-found">No Fault Found</label>
                            </label>
                        </li>
                        <li class="list-group-item" :class="{'active': selectedProblem === 'customer-misunderstanding'}">
                            <label class="custom-control custom-radio mb-0">
                                <input type="radio" class="custom-control-input" name="problem" value="customer-misunderstanding" id="problem-customer-misunderstanding" v-model="selectedProblem">
                                <label class="custom-control-label mb-0" for="problem-customer-misunderstanding">Customer Misunderstanding</label>
                            </label>
                        </li>
                    </ul>
                </div>

                <div v-if="noFaultFoundHasBeenSelected && selectedProblem === 'customer-misunderstanding'" class="form-group mt-4">
                    <h5>Describe Customer Education Event</h5>
                    <textarea v-model="customerEducationEventInput" class="form-control" rows="6" placeholder=""></textarea>
                </div>
            </template>

            <template v-if="showSubmit">
                <empty-state
                    statusIcon="success"
                    title="Device has been marked as SAW Request Required.">
                </empty-state>
                <hr>
                <p>
                    Please submit Inspection Fee SAW request on GSPN – Refer to SOP:
                    <a target="_blank" href="https://drive.google.com/file/d/1dzGFjyySioeaxuUAYA-4whoqKG_x8Z-T/view?usp=sharing">[W16] SAW Inspection Guide</a>
                    from the
                    <a target="_blank" href="https://sites.google.com/wefix.co.uk/trainingandcompliance">Training & Compliance Website</a>
                </p>
                <div class="mb-4">
                    SO Number: <strong>{{ serviceOrderNumber }}</strong>
                </div>
                <div>
                    <h6>Have you submitted a SAW request for this device?</h6>
                    <div class="row">
                        <div class="col-6">
                            <button @click="showSawRequestRequiredMessage()" class="btn btn-info btn-block mb-3">
                                <span class="text-sm ml-2">No</span>
                            </button>
                        </div>
                        <div class="col-6">
                            <button @click="submit" class="btn btn-info btn-block mb-3" :disabled="submitting">
                                <i class="fa fa-pulse fa-spinner" v-if="submitting"></i> <span>Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </template>

            <!-- Footer -->
            <fixed-footer
                    v-if="showFooter"
                    :class="{'active': canProgressToNextStep}"
                    :nextLabel="this.nextRepairStep($route.name).title"
                    :nextRoute="this.nextRepairStep($route.name).routeName"
                    :prevRoute="this.prevRepairStep($route.name).routeName">
            </fixed-footer>

        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {

        props:['jobId', 'deviceId'],

        data() {
            return {
                loading: false,
                submitting: false,
                selectedOption: null,
                selectedProblem: null,
                customerEducationEventInput: ""
            }
        },

        mounted() {
            this.loadDeviceForJob({
                jobId: this.jobId,
                deviceId: this.deviceId,
            }).then(() => {
                // Only allow IW devices
                if(this.device && this.device.in_warranty === false) {
                    this.$router.push({name: 'repair.symptomCodes'});

                } else {
                    this.setCurrentStep({
                        jobId: this.jobId,
                        deviceId: this.deviceId,
                        currentStep: this.$route.name,
                    });
                }
            });
        },

        watch: {
            selectedOption(option) {
                // Reset nested data values when switching top level option
                this.selectedProblem = null;
                this.customerEducationEventInput = "";

                if (option === 'software-upgrade') {
                    this.selectedProblem = 'no-fault-found';
                }
            }
        },

        computed: {
            ...mapGetters([
                "device",
                "isJobManager",
                "nextRepairStep",
                "prevRepairStep"
            ]),

            /** Check if footer should be active */
            canProgressToNextStep() {
                if(this.selectedOption === "device-fault") {
                    return true;
                }

                if(this.noFaultFoundHasBeenSelected || this.selected) {
                    if(!this.selectedProblem) {
                        return false;
                    }
                    if(this.selectedProblem === "customer-misunderstanding" && this.customerEducationEventInput === "") {
                        return false;
                    }
                    return true;
                }

                return false;
            },

            noFaultFoundHasBeenSelected() {
                return this.selectedOption === "no-fault-found";
            },

            softwareUpdateHasBeenSelected() {
                return this.selectedOption === "software-upgrade";
            },

            showFooter() {
                if(this.noFaultFoundHasBeenSelected || this.softwareUpdateHasBeenSelected) {
                    return false;
                }
                return this.canProgressToNextStep || this.isJobManager;
            },

            showSubmit() {
                return (!this.showFooter && this.selectedProblem !== null)
            },

            serviceOrderNumber() {
                return (this.device && this.device.so_number) ? this.device.so_number : '';
            }
        },

        methods: {
            ...mapActions([
                "loadDeviceForJob",
                "setCurrentStep",
                "setIrisCodeOne",
                "setIrisCodeTwo",
                "setIrisCodeThree",
                "setIrisCodeFour",
                "setDeviceStatus",
                "displayToast"
            ]),

            submit() {
                if (this.submitting) return;

                this.submitting = true;

                this.$http.post(`api/v4/orders/devices/${this.deviceId}/no-fault-found`, {
                    group_code_1: "1",
                    group_code_2: "38A",
                    group_code_3: (this.selectedProblem === "customer-misunderstanding") ? "4" : "3",
                    group_code_4: "Y",
                    customer_education_event_details: this.customerEducationEventInput
                }).then(() => {
                    // SAW Request Required
                    this.setDeviceStatus({
                        jobId: this.jobId,
                        deviceId: this.deviceId,
                        status: 22,
                        subStatusId: null
                    }).then(() => {
                        this.$router.push({name: 'job', props: this.jobId});
                    }).catch((response) => {
                        this.displayToast({text: response.response.data.messages[0], type: 'error', duration: 6000});
                        this.submitting = false;
                    });
                }).catch((response) => {
                    this.displayToast({text: response.response.data.messages[0], type: 'error', duration: 6000});
                    this.submitting = false;
                });
                return true;
            },

            showSawRequestRequiredMessage() {
                this.displayToast({
                    text: 'SAW request is required to complete this job',
                    duration: 3000,
                    type: 'error'
                })
            }
        }
    }
</script>